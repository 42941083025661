import React, { Fragment, useEffect, useState } from "react"
import { HeadFC, Link, graphql } from "gatsby"

import ArrowWhite from "../assets/img/arrow-right.webp"
import ClutchWhite from "../assets/img/clutchWhite.webp"

import AccMinus from "../assets/img/acc-minus.inline.svg"
import AccPlus from "../assets/img/acc-plus.inline.svg"

import ArrowUpblack from "../assets/img/arrow-up-black.webp"

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useContextValues } from "../context/NavBarContext"

import TestimonialSlider from "../components/DashboardTestimonials/TestimonialSlider"
import Faq from "../components/PPC/ReactDevPpc/Faq"
import TechnologyServiceAccordian from "../components/TechnologyServiceAccordian"
import TechnologyKeyTrendsAccordian from "../components/TechnologyKeyTrendsAccordian"
import useSiteMetadata from "../hooks/useSiteMetadata"
import SEO from "../components/SEO"
import PortfolioDetail from "../components/DashboardPortfolio/PortfolioDetail"
import Contact from "../components/Contact"

const TechnologyPage = ({
    data: {
        technologiesNewJson: post,
        allServicesPortfolioJson: { portfolio },
    },
}) => {

    const [topBlogs, setTopBlogs] = useState([])
    const [isOpenOnDemandEngagemeModel, setOnDemandEngagementModel] = useState(false)
    const [showingBusinessBenefit, setShowingBusinessBenefit] = useState<Number>()

    const { isMobile, changeCurrentPath, changeNavColor } = useContextValues()

    useEffect(() => {
        changeCurrentPath(location.pathname)
        changeNavColor("white")
        getBlogs()
        return (() => changeNavColor("black"))
    }, [])

    const fetchBlogMethod = async (blog_category_id = 5) => {
        try {
            const response = await fetch(process.env.GATSBY_GET_BLOGS_API + `${blog_category_id}`)
            if (response.ok) {
                return response.json()
            }
            return []
        } catch (e) {
            console.log("Error fetching blogs: ", e);
        }
    }

    const getBlogs = async () => {
        let blogs = []
        blogs = await fetchBlogMethod(post.blog_category_id || 5)
        if (!blogs.length) {
            blogs = await fetchBlogMethod()
        }
        setTopBlogs(blogs)
    }

    const decodeHTMLEntities = (text) => {
        const textarea = document.createElement("textarea")
        textarea.innerHTML = text
        const decodedText = textarea.value
        textarea.remove()
        return decodedText
    }

    post.heroImage = getImage(post.heroImage)

    return (
        <>
            <section className="banner-gradient pt-[3.75rem] md:pt-[9.6875rem] md:pb-[6.25rem] pb-[3.125rem] lg:pb-[8.5rem] bg-[#181818] relative before:content-[''] before:w-[56.5625rem] before:h-[56.5625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-0 z-[1] before:-z-50 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden">
                <div className="container">
                    <div className="banner-grid flex flex-wrap justify-center lg:grid lg:grid-cols-[auto_386px] grid-cols-[1fr] gap-[2.5rem] md:gap-[3.125rem] max-lg:grid-cols-[1fr_386px]">
                        <div className="grid-left order-1 lg:order-[-1]">
                            <p className="small-title uppercase text-sm text-white mb-[1.875rem]">{post.paragraph_1}</p>
                            <h1 className="text-white font-blancomedium text-[2rem] lg:text-[3.25rem] leading-[1.15] mb-[1.375rem]">
                                {post.title_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h1>
                            <p className="text-white text-lg leading-[1.66] font-gordita">{post.description_1}</p>
                            <div className="btn-row mt-[3.125rem]">
                                <Link to="/contact-us/" className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] max-sm:px-[1.25rem] hover:bg-transparent  uppercase">Get a Free 30 MinuteS Consultation
                                    <img
                                        src={ArrowWhite}
                                        alt="RightArrow"
                                        className="w-[7px] h-[14px] mt-[-3px]"
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="grid-right relative flex flex-wrap items-center justify-center flex-col">
                            <p className="w-[20rem] h-[20rem] lg:w-[24.125rem] lg:h-[24.125rem] bg-[url('../assets/img/dots-bg.svg')] bg-center bg-no-repeat flex flex-wrap items-center justify-center">
                                <span className="inline-block bg-white-200 pt-[2.1875rem] px-[2.0625rem] py-[1.9375rem] rounded-[2.5rem] p-[2.1875rem]">
                                    <GatsbyImage image={post.heroImage} alt={post.alt} className="lg:w-[5.25rem] lg:h-[5.25rem] w-[4.375rem] h-[4.375rem]" objectFit="contain" />
                                </span>
                            </p>
                            <p className="max-sm:block flex flex-wrap items-center gap-[0.25rem] text-white text-xs leading-[0.9375rem] bg-[#1C1C1C] rounded-[0.625rem] py-[0.625rem] px-6">
                                <img
                                    src={ClutchWhite}
                                    alt="Clutch"
                                    className="max-w-[3.75rem] mr-[0.375rem] max-sm:block max-sm:mx-auto max-sm:mb-[10px]"
                                />
                                <span className="font-gorditamedium max-sm:align-middle max-sm:mr-[0.3125rem]">4.9/5</span>
                                <StaticImage
                                    src="../assets/img/rating-stars.webp"
                                    alt="Reviewed rating"
                                    placeholder="blurred"
                                    className="inline-block w-[3.875rem] align-middle ml-[0.375rem] max-lg:mx-auto max-sm:mr-[0.3125rem]"
                                />
                                based on <span className="font-gorditamedium">43</span> verified reviews
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lg:py-[6.875rem] md:py-[3.125rem] py-[1.875rem]">
                <div className="container md:max-w-[50.9375rem] max-sm:px-[1.25rem] px-5">
                    <div className="title text-center">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-8 font-blancomedium text-center">
                            {post.title_2.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <p className="text-lg">
                            {post.description_2.map((text, idx) => text[0] ? <Link key={idx} to={text[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{text[1]}</Link> : text[1])}
                        </p>
                    </div>
                    <div className="mt-[2.1875rem]">
                        <TechnologyServiceAccordian data={post.service_accordian} isEdtech={false} />
                    </div>
                </div>
            </section>

            {!post.isIotBlockchain && <>
                <section className="lg:pb-[6.875rem] overflow-hidden md:pb-[3.125rem] pb-[1.875rem]">
                    <div className="container">
                        <div className="grid-div bg-[#343434] max-sm:px-[20px] py-[1.4375rem] pl-[3.75rem] pr-[1.875rem] rounded-[1.875rem] grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[auto_270px] gap-[1.5625rem] md:gap-[3.125rem] lg:gap-[5rem] green-bg-gradient relative before:content-[''] before:w-[37.0625rem] before:h-[37.0625rem] before:absolute before:bg-no-repeat before:top-[-5rem] before:right-[-6.875rem] z-[1] before:-z[-1] before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden">
                            <div className="grid-content">
                                <p className="font-gorditamedium text-sm leading-[1.5] text-white mb-[0.75rem]">CASE STUDY</p>
                                <p className="text-[1.375rem] text-white leading-[1.45] font-gorditaregular font-normal">
                                    {post.case_study.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                </p>
                                <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                                    <Link to={post.case_study.button[0]} className="lg:text-lg relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 ">{post.case_study.button[1]}
                                    </Link>
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear mt-[3px]"
                                    />
                                </div>
                            </div>
                            <div className="grid-img relative z-10">
                                <GatsbyImage image={getImage(post.case_study.case_study_img)!} alt={post.case_study.case_study_img_alt} className="rounded-[0.625rem] block mx-auto md:inline-block" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-[#F8F7F3] lg:py-[6.875rem] md:py-[3.125rem] py-[1.875rem]">
                    <div className="container max-w-[67.8125rem] max-[991px]:max-w-[100%]">
                        <div className="benefit bg-[#fff] rounded-[1.25rem] border border-[#D3D8E0]">
                            <h2 className="text-black text-[2rem] leading-[2.1875rem] text-center lg:leading-[3.375rem] font-blancomedium py-[1.875rem] md:px-[1.875rem] lg:px-[3.3125rem] border-b border-[#D3D8E0] max-sm:px-5">
                                {post.benefits.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h2>
                            <div className="grid pt-[1.875rem] pb-[3.125rem] lg:px-[3.125rem] px-[25px] lg:gap-[5.625rem] gap-[3.125rem] grid-cols-2 max-sm:grid-cols-[1fr]">
                                <div className="grid-left">
                                    <div className="text-center">
                                        <span className="font-gorditaregular inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.375rem] pb-[0.375rem] px-[1.375rem] rounded-[6.25rem] text-sm border border-black mb-[1.875rem]">{post.benefits.left_title}</span>
                                    </div>
                                    <ul className="text-left">
                                        {post.benefits.left_benefits.map((benefit, idx) =>
                                            <li key={`left_benefit ${idx}`} className=" text-black text-base leading-relaxed relative before:content-[''] before:w-[1.125rem] before:h-[1.125rem] before:absolute before:left-0 before:top-[0.125rem] before:bg-[url('../assets/img/tick-circle.webp')] before:bg-center before:bg-no-repeat block pl-[2.375rem] pb-5 max-sm:pb-[0.625rem] min-h-[4.25rem]">
                                                {benefit}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="grid-right">
                                    <div className="text-center">
                                        <span className="font-gorditaregular inline-block bg-cream italic md:pt-[0.563rem] md:pb-[0.375rem] pt-[0.375rem] pb-[0.375rem] px-[1.375rem] rounded-[6.25rem] text-sm border border-black mb-[1.875rem]">{post.benefits.right_title}</span>
                                    </div>
                                    <div>
                                        <ul>
                                            {post.benefits.right_benefits.map((benefit, idx) => {
                                                let totalCharacters = 0
                                                return (
                                                    <li key={`right_benefit ${idx}`} className={`md:max-w-[24rem] relative pr-[1.875rem] ${idx === 0 ? '' : 'pt-5'} ${showingBusinessBenefit === idx ? '' : 'line-clamp-2'}`}>
                                                        {benefit.map((chunk, idx) => {
                                                            totalCharacters += chunk[1].length
                                                            return (
                                                                chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1]
                                                            )
                                                        })}
                                                        {totalCharacters > 87 && <span className={`expand-content cursor-pointer absolute w-[1.375rem] right-0 ${idx === 0 ? 'top-0' : 'top-5'}`}>
                                                            {showingBusinessBenefit === idx ? <AccMinus onClick={() => setShowingBusinessBenefit(undefined)} className="w-[1.375rem] h-[1.375rem]" /> : <AccPlus onClick={() => setShowingBusinessBenefit(idx)} className="w-[1.375rem] h-[1.375rem]" />}
                                                        </span>}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-[47.5rem] border border-[rgba(0, 0, 0, 0.08)] mx-auto mt-[1.875rem] rounded-[1rem] bg-[#F1EFE8] py-[1.25rem] px-[1.875rem]">
                            <p className="uppercase text-base leading-[1.5] font-medium mb-[0.625rem]">DO YOU KNOW ?</p>
                            <p className="text-base leading-[1.625]">
                                {post.do_you_know.map((text, idx) => text[0] ? <strong key={idx} className={text[0]}>{text[1]}</strong> : text[1])}
                            </p>
                        </div>
                    </div>
                </section>

                <section className="bg-[#343434] lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[1.875rem] before:content-[''] before:w-[37.0625rem] before:h-[37.0625rem] before:absolute before:bg-no-repeat before:top-auto before:bottom-[-10.625rem] before:right-[-10rem] z-[1] before:-z-1 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden relative green-bg-gradient">
                    <div className="container">
                        <div className="excellence-grid grid max-[991px]:grid-cols-1 lg:grid-cols-[400px_1fr_1fr] xl:gap-[90px] lg:gap-[40px] gap-[30px] items-center ">
                            <div className="title-grid">
                                <div className="title lg:max-w-[19.375rem]">
                                    <h2 className="text-white text-[2rem] leading-[45px] font-blancomedium pb-[0.625rem] lg:pb-5"><span className="text-primary italic "> Excellence:</span> The standard for our client deliveries.</h2>
                                </div>
                                <p className="text-white text-base font-normal font-['Gordita'] leading-relaxed mb-0 lg:mb-10">With over 30 awards and accolades, our achievements showcase our quality and commitment to client success.</p>
                                <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                                    <Link to="/contact-us/" className="text-lg relative leading-[1.5] italic font-gorditamedium text-primary before:content-[''] before:w-full before:h-[1px] before:bg-primary before:absolute before:left-0 before:bottom-0 pr-5">{post.accelerate}
                                    </Link>
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear absolute right-0 md:top-[6px] top-[18px]"
                                    />
                                </div>
                            </div>
                            <div className="excellence-num flex flex-wrap items-center justify-center lg:flex-col gap-5 lg:gap-0">
                                <div className="w-[10rem] h-[10rem] border border-white/20 rounded-full inline-flex flex-wrap items-center justify-center flex-col px-[1.875rem]">
                                    <span className="block text-center text-white text-[1.375rem] lg:text-[2rem] xl:text-[2.625rem] font-gorditamedium lg:leading-[3.375rem]">500+</span>
                                    <span className="block text-center text-white text-base font-gorditamedium">Projects delivered </span>
                                </div>
                                <div className="w-[10rem] h-[10rem] border border-white/20 rounded-full inline-flex flex-wrap items-center justify-center flex-col px-[1.875rem] lg:mt-[2rem] ">
                                    <span className="block text-center text-white text-[1.375rem] lg:text-[2rem] xl:text-[2.625rem] font-gorditamedium lg:leading-[3.375rem]">12+</span>
                                    <span className="block text-center text-white text-base font-gorditamedium">Years of experience </span>
                                </div>
                            </div>
                            <div className="logo-images">
                                <ul className="flex flex-wrap items-center gap-5 justify-center lg:grid lg:grid-cols-[auto_auto] lg:gap-5  xl:gap-[3.125rem] text-center">
                                    <li className="inline-block"><StaticImage placeholder="blurred" src="../assets/img/inner-banner-logo1.inline.svg" alt="Shell" class="w-[3.125rem]" /></li>
                                    <li className="inline-block"><StaticImage placeholder="blurred" src="../assets/img/countlyWhite.inline.svg" alt="countly" class="w-[8.3125rem]" /></li>
                                    <li className="inline-block"><StaticImage placeholder="blurred" src="../assets/img/di.inline.svg" alt="data Intelligence" class="w-[11.25rem]" /></li>
                                    <li className="inline-block"><StaticImage placeholder="blurred" src="../assets/img/humanX.inline.svg" alt="humanX" class="w-[7.125rem]" /></li>
                                    <li className="inline-block"><StaticImage placeholder="blurred" src="../assets/img/sculpey.inline.svg" alt="sculpey" class="w-[4.25rem] h-[4.25rem]" /></li>
                                    <li className="inline-block"><StaticImage placeholder="blurred" src="../assets/img/getlit-logo.inline.svg" alt="getLitte" class="[4.75rem]" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[2.5rem] bg-[#F5F5F5]">
                    <div className="container md:max-w-[57.5rem] px-5">
                        <div className="title">
                            <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.5rem] lg:leading-[3.125rem] font-blancomedium text-center md:max-w-[42.5rem] mx-auto mb-[3.125rem]">
                                {post.title_3.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h2>
                        </div>
                        <div className="grid grid-cols-2 gap-[1.875rem] max-sm:grid-cols-[1fr]">
                            {post.expertise.map((experty, idx) => {
                                return (
                                    <div key={`experty ${idx}`} className="expertise-list rounded-[1.25rem] bg-[#fff] drop-shadow-[0px_23px_34.3px_rgba(0,0,0,0.05)] p-5">
                                        <span className={`inline-block mb-[0.75rem] ${experty.tech_img.length > 1 ? 'inline-flex items-center border-[1.8px] border-[#D3D8E0] bg-[#F4F5F5] pt-[6px] pb-1 px-4 rounded-[4.375rem]' : ''}`}>
                                            {experty.tech_img.map((img, idx) =>
                                                <Fragment key={`tech-img-${idx}`}>
                                                    <GatsbyImage image={getImage(img)!} alt={experty.title} className={experty.tech_img.length > 1 ? 'w-[1.75rem] h-auto' : 'w-10 h-10'} objectFit="contain" />
                                                    {idx !== experty.tech_img.length - 1 && <span className="text-xl inline-block mx-2">+</span>}
                                                </Fragment>
                                            )}
                                        </span>
                                        <h3 className="text-[#010101] text-lg leading-[1.55] font-gorditamedium">{experty.title}</h3>
                                        <p className="text-black text-base leading-[24px] mt-[0.375rem]">
                                            {experty.description.map((chunk, idx) => chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1])}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>

                <section id="our-work" className="pt-[40px] lg:pb-[50px] md:pb-11 pb-0">
                    <div className="container">
                        <div className="title-sec text-center md:max-w-[53.125rem] m-auto">
                            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-8 font-blancomedium">Projects<span className="text-[#4BD965] italic"> powered by</span> our development team.</h2>
                        </div>
                        {
                            post.portfolios_slug.map((slug, idx) => (<PortfolioDetail key={`portfolio-${idx}`} {...portfolio.find((project) => slug === project.slug)} showBudget={false} showProjectTechnologies />))
                        }
                    </div>
                </section>

                <section id="launch" className="lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[1.875rem] bg-[#F5F5F5]">
                    <div className="container">
                        <div className="title text-center">
                            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-5 md:max-w-[46.875rem] mx-auto">
                                {post.experience.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h2>
                            {post.experience.p1.map((paragraph, idx) =>
                                <p key={idx} className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mb-5">
                                    {paragraph.map((chunk, idx) => chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1])}
                                </p>
                            )}
                        </div>
                        {post.experience.experience_image && <div className="mt-[1.875rem] lg:w-[910px] mx-auto w-full">
                            <GatsbyImage image={getImage(post.experience.experience_image)!} alt="" className="w-full object-cover object-top max-sm:h-auto" />
                        </div>}
                        {post.experience.p2.map((paragraph, idx) =>
                            <p key={idx} className="text-black text-base leading-[1.625] text-center md:max-w-[950px] mx-auto mt-[1.875rem]">
                                {paragraph.map((chunk, idx) => chunk[0] ? <Link key={idx} to={chunk[0]} className="text-[#0B57D0] underline italic hover:text-primary font-gorditamedium">{chunk[1]}</Link> : chunk[1])}
                            </p>
                        )}
                    </div>
                </section>

                <section className="lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[1.875rem] bg-[#F8F7F3]">
                    <div className="container">
                        <div className="title text-center">
                            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mb-5 lg:mb-10 md:max-w-[50rem] mx-auto">
                                {post.title_4.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h2>
                        </div>
                        <div className="tabs-sec flex flex-wrap items-center justify-center max-w-fit lg:max-w-[22.813rem] mx-auto mb-[3.125rem] lg:mb-[4.313rem] py-[0.563rem] px-[0.688rem] bg-[#E8E3FF] rounded-[6.25rem] text-xs md:text-sm font-gorditamedium leading-[1.125rem] md:leading-6">
                            <div
                                className={`py-[0.563rem] pb-[0.313rem] px-[1.438rem] rounded-[6.25rem] cursor-pointer ${!isOpenOnDemandEngagemeModel && 'bg-[#ffffff] italic'}`}
                                onClick={() => setOnDemandEngagementModel(false)}
                            >Outsource development</div>
                            <div
                                className={`py-[0.563rem] pb-[0.313rem] px-4 md:px-[1.438rem] rounded-[6.25rem] cursor-pointer ${isOpenOnDemandEngagemeModel && 'bg-[#ffffff] italic'}`}
                                onClick={() => setOnDemandEngagementModel(true)}
                            >On-demand</div>
                        </div>

                        {isOpenOnDemandEngagemeModel ? (
                            <div className="grid lg:grid-cols-[360px_1fr] grid-cols-[1fr_1fr] lg:gap-[8.3125rem] gap-5 max-sm:grid-cols-1 engagement-model">
                                <div className="lg:sticky md:top-24 md:left-0">
                                    <div className="lg:max-w-[27.313rem] lg:sticky md:top-24 md:left-0">
                                        <div className="">
                                            <h3 className="font-blancomedium text-[1.75rem] leading-[2.375rem] md:leading-[2.938rem] md:text-4xl mb-3 md:mb-5 md:max-w-[359px]">Outsource development</h3>
                                            <p className="mb-[30px]  md:max-w-[359px]">
                                                {post.on_demand.paragraph}
                                            </p>
                                            <Link
                                                to="/contact-us/"
                                                className="inline-flex gap-[0.625rem] items-center bg-white-200 justify-start inline-white text-black border border-white shadow-1xl text-base group rounded-lg font-gorditamedium group ease-in duration-300 pl-5 pt-[12px] pb-[12px] pr-5 hover:bg-primary bg-white hover:border-primary"
                                            >Share your project requirements
                                                <span className="inline-flex justify-center items-center rounded-full overflow-hidden relative ">
                                                    <img
                                                        src={ArrowUpblack}
                                                        alt="ArrowRight"
                                                        className="w-[0.75rem] h-[0.75rem] rotate-[90deg] object-contain"
                                                    />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:pl-20 lg:mt-[0.9375rem] mt-5 model-tab-content">
                                    <ul className="relative z-10 max-lg:before:left-6 before:content-[''] before:absolute before:bg-[#DFDCD3] before:-z-10 before:w-[0.063rem] before:h-full before:top-0 before:left-[1.875rem]">
                                        {post.on_demand.points.map((point, idx) =>
                                            <li key={`demand ${idx}`} className="relative pl-[3.875rem] lg:pl-[5.625rem] mb-9 lg:mb-[4.5rem]">
                                                <span className="absolute left-0 -top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] bg-primary rounded-[50%] flex items-center justify-center mb-5 font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                                    <GatsbyImage image={getImage(point.demand_img)!} alt={point.title} />
                                                </span>
                                                <h4 className="font-blancomedium text-xl lg:text-2xl mb-2 lg:mb-[0.875rem] md:mb-5">{idx + 1}. {point.title}</h4>
                                                <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{point.description}</p>
                                            </li>
                                        )}

                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="grid lg:grid-cols-[360px_1fr] grid-cols-[1fr_1fr] lg:gap-[8.3125rem] gap-5 max-sm:grid-cols-1 engagement-model">
                                <div className="lg:sticky md:top-24 md:left-0">
                                    <div className="">
                                        <h3 className="font-blancomedium text-[1.75rem] leading-[2.375rem] md:leading-[2.938rem] md:text-4xl mb-3 md:mb-5 md:max-w-[359px]">Outsource development</h3>
                                        <p className="mb-[30px]  md:max-w-[359px]">{post.outsource_development.paragraph}</p>
                                        <Link
                                            to="/contact-us/"
                                            className="inline-flex gap-[0.625rem] items-center bg-white-200 justify-start inline-white text-black border border-white shadow-1xl text-base group rounded-lg font-gorditamedium group ease-in duration-300 pl-5 pt-[12px] pb-[12px] pr-5 hover:bg-primary bg-white hover:border-primary"
                                        >Share your project requirements
                                            <span className="inline-flex justify-center items-center rounded-full overflow-hidden relative ">
                                                <img
                                                    src={ArrowUpblack}
                                                    alt="ArrowRight"
                                                    className="w-[0.625rem] h-[0.625rem] rotate-[90deg] object-contain"
                                                />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="lg:pl-20 lg:mt-[0.9375rem] mt-5 model-tab-content">
                                    <ul className="relative z-10 max-lg:before:left-6 before:content-[''] before:absolute before:bg-[#DFDCD3] before:-z-10 before:w-[0.063rem] before:h-full before:top-0 before:left-[1.875rem]">
                                        {post.outsource_development.points.map((point, idx) =>
                                            <li key={`outsource ${idx}`} className="relative pl-[3.875rem] lg:pl-[5.625rem] mb-9 lg:mb-[4.5rem]">
                                                <span className="absolute left-0 -top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] bg-primary rounded-[50%] flex items-center justify-center mb-5 font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">
                                                    <GatsbyImage image={getImage(point.out_dev_img)!} alt={point.title} />
                                                </span>
                                                <h4 className="font-blancomedium text-xl lg:text-2xl mb-2 lg:mb-[0.875rem] md:mb-5">{idx + 1}. {point.title}</h4>
                                                <p className="text-[0.938rem] md:text-base leading-[1.625rem]">{point.description}</p>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

                <section id="technical-stack" className="py-10 md:py-12 lg:pt-[110px] pb-[3.125rem] lg:pb-0">
                    <div className="container">
                        <div className="title-sec text-center">
                            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                                {post.ecosystem?.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            </h2>
                        </div>
                        <div className="stack-wrapper mt-[1.875rem]">
                            {post.ecosystem?.groups.map((group, idx) =>
                                <div key={`point ${idx}`} className="bg-[#F7F8F9] px-[1.688rem] pl-0 md:rounded-lg md:mb-[1.688rem] max-md:px-0  md:flex">
                                    <p className="lg:pl-[1.688rem] bg-[#343434] lg:min-w-[185px] xl:w-[295px] md:min-w-[220px] md:max-w-[220px] lg:pt-[1.625rem] lg:pb-[1.438rem] md:pt-[0.875rem] md:px-5 md:pb-[0.625rem] max-md:p-4 max-md:pb-[10px] md:rounded-l-lg text-base lg:text-lg font-gorditamedium italic inline-block max-md:mb-4 text-white max-md:w-full md:flex md:flex-wrap md:items-center ">{group.name}</p>

                                    <p className="inline-flex flex-wrap items-center lg:pl-[1.625rem] md:pl-3 text-xl font-gorditamedium max-md:pb-5 max-md:px-4 md:p-6 md:w-[calc(100% - 107px)] lg:w-auto max-md:pb-10 gap-y-[0.9375rem]">
                                        {group.items.map((img, idx) =>
                                            <span key={`img ${idx}`} className={`inline-flex flex-wrap items-center relative max-md:text-sm md:text-base ${idx === (group.items.length - 1) ? "" : "pr-3 mr-3 before:content-[''] before:w-[2px] before:h-5 before:bg-[#D3D8E0] before:absolute before:right-0 before:top-[50%] before:translate-y-[-50%]"}`}>
                                                {img.item_img && <span className="inline-block align-middle mr-2 w-[30px]">
                                                    <GatsbyImage image={getImage(img.item_img)!} alt={img.item_name} />
                                                </span>}
                                                {img.item_name}
                                            </span>
                                        )}
                                    </p>
                                </div>
                            )}

                        </div>
                        <div className="btn-row text-center mt-[50px]">
                            <Link to="/contact-us/" className="inline-flex gap-5 items-center bg-[#3FB955] justify-start inline-white text-white border border-[#3FB955] shadow-1xl text-sm md:text-base rounded-lg font-gorditamedium group ease-in duration-300 pt-[0.813rem] pb-[0.563rem] px-[2.063rem] hover:bg-transparent hover:text-black uppercase relative pr-[2.3125rem]">Schedule A Developer's Interview
                                <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent absolute right-[5px] top-[5px]">
                                    <img
                                        src={ArrowWhite}
                                        alt="ArrowRight"
                                        className="absolute left-1/2 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                    />
                                    <img
                                        src={ArrowWhite}
                                        alt="ArrowRight"
                                        className="absolute -left-16 top-4 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300 group-hover:invert-[1] group-hover:brightness-[1]"
                                    />
                                </span>
                            </Link>
                        </div>
                    </div>
                </section>

                {!isMobile && <section className="py-8  lg:py-10 xl:py-20 testimonial-sec md:py-[1.875rem]">
                    <div className="container">
                        <div className="w-full text-center lg:pb-10 pb-9">
                            <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] px-[1.625rem] rounded-[6.25rem] mb-6 md:mb-[0.625rem] leading-[1.125rem] md:leading-[21px]">Testimonials</span></div>
                            <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium md:max-w-[43.75rem] md:mx-auto">
                                Techuz is one of the <span className="italic text-black md:text-primary">best development and IT firm</span> in the world
                                <span className="inline-block text-orangecol">.</span>
                            </h2>
                            <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">And here’s what our clients say about us</p>
                        </div>

                        <TestimonialSlider />

                    </div>
                </section >}

                <section className="bg-[#343434] lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[1.875rem] before:content-[''] before:w-[37.0625rem] before:h-[37.0625rem] before:absolute before:bg-no-repeat before:top-auto before:bottom-[-10.625rem] before:right-[-10rem] z-[1] before:-z-1 before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden relative green-bg-gradient">
                    <div className="container">
                        <div className="grid xl:grid-cols-[1fr_560px] xl:gap-[5.625rem] lg:gap-[3.125rem] md:grid-cols-[1fr_1fr] gap-[1.875rem]">
                            <div className="left-content">
                                <h2 className="text-white text-[2.125rem] max-md:text-[32px] leading-[2.625rem] lg-[1055px]:text-[2.625rem] md:leading-[45px] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                                    {post.key_trends.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}> {text[1]} </span> : text[1])}
                                </h2>
                                {post.key_trends.description.map((p, idx) =>
                                    <p key={`paragraph ${idx}`} className="text-white mb-5 leading-[1.625rem]">
                                        {p.map((line, idx) =>
                                            line[0]
                                                ? <Link key={idx} to={line[0]} className="font-gorditamedium text-white italic underline hover:text-primary mr-1 ml-1">{line[1]}</Link>
                                                : line[1]
                                        )}
                                    </p>
                                )}
                                <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] lg:mt-4 group transition duration-500 ease-linear">
                                    <Link to="/contact-us/" className="ltext-lg relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 ">{post.key_trends.btn_text}
                                    </Link>
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="w-[7px] h-[12px] group-hover:translate-x-1 transition duration-500 ease-linear"
                                    />
                                </div>
                            </div>
                            <div className="right-content">
                                <TechnologyKeyTrendsAccordian keyTrends={post.key_trends.accordian} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="lg:pt-[4.0625rem] lg:pb-[4.3125rem] md:py-[3.125rem] py-[1.875rem] ">
                    <div className="container">
                        <div className="wrapper flex flex-wrap lg:grid lg:grid-cols-[auto_410px] xl:gap-[5.625rem] lg:gap-[3.125rem] gap-[2.5rem] items-start">
                            <div className="left w-full">
                                <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-3 lg:pb-5 font-blancomedium">
                                    {post.insight_title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                </h2>
                                <ul className="grid grid-cols-3 gap-[0.625rem] max-sm:grid-cols-1">
                                    {topBlogs.map(({ id, title: { rendered }, better_featured_image: { source_url, alt_text }, link }) => (
                                        <Link key={`blog-${id}`} to={link} className="pt-[0.625rem] px-[0.625rem] pb-5 border-[0.125rem] border-[#FFF2E3] rounded-[0.625rem]">
                                            <span>
                                                <img src={source_url} alt={alt_text} loading="lazy" className="rounded-lg w-full h-[12.5rem] md:h-[6.0625rem] object-cover" />
                                            </span>
                                            <p className="text-black text-sm leading-[1.714] font-gorditamedium mt-[0.5625rem]" >{decodeHTMLEntities(rendered)}</p>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                            <div className="right p-5 md:px-10 md:pt-10 md:pb-[4.0625rem] rounded-[1.875rem] bg-[url('../assets/img/strings-bg.webp')] bg-center bg-no-repeat bg-cover ">
                                <h3 className="font-blancomedium text-[1.375rem] mb-[1.875rem]">Similar technologies</h3>
                                <ul className="grid grid-cols-2 gap-[0.9375rem]">
                                    {post.similar_technologies.map(({ tech_img, tech_name, url }, idx) => (
                                        <Link key={`tech-${idx}`} to={url} className="py-[0.375rem] px-5 bg-white-200 rounded-[6.25rem] border border-[#FFF2E3] drop-shadow-[0px_23px_34.3px_rgba(0,0,0,0.05)] flex flex-wrap items-center min-w-[7.6875rem] pt-[0.625rem]">
                                            {tech_img[0] && <span className="inline-flex w-[1.3125rem] mr-2 items-center">
                                                {tech_img[0].extension === 'svg'
                                                    ? (
                                                        <img
                                                            loading="lazy"
                                                            src={tech_img[0].publicURL}
                                                            alt={tech_name}
                                                            className="w-full"
                                                        />
                                                    )
                                                    : <GatsbyImage image={getImage(tech_img[0])!} alt={tech_name} className="w-full" />
                                                }
                                            </span>}
                                            <span className="text-sm leading-[18px] font-gorditamedium">{tech_name}</span>
                                        </Link>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Faq faqData={post.faqs} />
            </>}

            <Contact />


        </>
    )
}

export const query = graphql`
  query ($slug: String!, $portfolios_slug: [String]) {
    technologiesNewJson(slug: { eq: $slug }) {
      seo_title
      seo_description
      application_script
      slug
      heroImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
        name
        publicURL
        extension
      }
      alt
      title_1
      description_1
      paragraph_1
      title_2
      description_2
      service_accordian {
        title
        description
      }
      isIotBlockchain
      case_study {
        title
        button
        case_study_img {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      benefits {
        title
        left_title
        left_benefits
        right_title
        right_benefits
      }
      do_you_know
      accelerate
      title_3
      expertise {
        description
        title
        tech_img {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      portfolios_slug
      experience {
        title
        p1
        p2
        experience_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      title_4
      outsource_development {
        paragraph
        points {
          description
          title
          out_dev_img {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      on_demand {
        paragraph
        points {
          title
          description
          demand_img {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ecosystem {
        title
        groups {
          name
          items {
            item_name
            item_img {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
      key_trends {
        title
        description
        btn_text
        accordian {
          title
          description
        }
      }
      insight_title
      similar_technologies {
        tech_name
        url
        tech_img {
          childImageSharp {
            gatsbyImageData (placeholder: BLURRED)
          }
          extension
          publicURL
        }
      }
      blog_category_id
      faqs {
        key
        answer
        question
      }
    }
    allServicesPortfolioJson(filter: { slug: { in: $portfolios_slug } }) {
      portfolio: nodes {
        title
        slug
        subTitle
        description
        clutchLink
        industry
        country
        budget
        backgroundStyles
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          publicURL
        }
        featuredImageAlt
      }
    }
  }
`
export default TechnologyPage

export const Head: HeadFC = ({ location, data }) => {
    const { siteUrl } = useSiteMetadata()
    const { seo_title, seo_description, application_script } = data["technologiesNewJson"]

    return (
        <SEO
            title={seo_title}
            canonical={`${siteUrl}${location.pathname}`}
            description={seo_description}
        >
            {!!application_script && <script type="application/ld+json">{application_script}</script>}
        </SEO>
    )
}
